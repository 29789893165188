import epic_buck_converter_intro from './epic_buck_converter_intro.md';

const epicBuckConverterIntro = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "Epic Buck Converter: Intro",
  url: "epic_buck_converter_intro",
  postFile: epic_buck_converter_intro
};

export default epicBuckConverterIntro;