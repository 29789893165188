import part4 from './part4.md';

const howToLandAnEEInternshipPart4 = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How to Land an Electrical Engineering Internship Part 4: Applying",
  filePath: "how_to_land_an_ee_internship/part4-applying",
  postFile: part4
};

export default howToLandAnEEInternshipPart4;