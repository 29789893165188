import part8 from './part8.md';

const howToLandAnEEInternshipPart8 = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How to Land an Electrical Engineering Internship Part 8: Closing",
  filePath: "how_to_land_an_ee_internship/part8-closing",
  postFile: part8
};

export default howToLandAnEEInternshipPart8;