import part6 from './part6.md';

const howToLandAnEEInternshipPart6 = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How to Land an Electrical Engineering Internship Part 6: Interviews",
  filePath: "how_to_land_an_ee_internship/part6-interviews",
  postFile: part6
};

export default howToLandAnEEInternshipPart6;