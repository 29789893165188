import part5 from './part5.md';

const howToLandAnEEInternshipPart5 = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How to Land an Electrical Engineering Internship Part 5: That awkward time between applications and interviews",
  filePath: "how_to_land_an_ee_internship/part5-awkward_period",
  postFile: part5
};

export default howToLandAnEEInternshipPart5;