import track_jobs_4_me_review from './track_jobs_4_me_review.md';

const trackJobs4MeReview = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How I Track my Hundreds of Job Applications: Trackjobs4.me Review",
  url: "track_jobs_4_me_review",
  postFile: track_jobs_4_me_review
};

export default trackJobs4MeReview;