import part7 from './part7.md';

const howToLandAnEEInternshipPart7 = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How to Land an Electrical Engineering Internship Part 7: The Offer!",
  filePath: "how_to_land_an_ee_internship/part7-offer",
  postFile: part7
};

export default howToLandAnEEInternshipPart7;