import first_post from './first_post.md';

const firstPost = {
  isDeployed: true,
  date: "Monday, December 14, 2020",
  title: "First Post!",
  url: "first_post",
  filePath: "first_post",
  postFile: first_post
};

export default firstPost;