import summer_2021_internship_search from './summer_2021_internship_search.md';

const summer2021InternshipSearch = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "Electrical Engineering Interships: My Summer 2021 Internship Search",
  url: "summer_2021_internship_search",
  postFile: summer_2021_internship_search
};

export default summer2021InternshipSearch;