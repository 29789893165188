import how_i_made_this_blog from './how_i_made_this_blog.md';

const howIMadeThisBlog = {
  isDeployed: true,
  isPreview: true,
  date: "???",
  title: "How I Made this Blog",
  url: "how_i_made_this_blog",
  postFile: how_i_made_this_blog
};

export default howIMadeThisBlog;